import { FC, useState } from 'react'
import { Box, CircularProgress } from '@mui/material'
import { observer } from 'mobx-react-lite'
import PaymentsService from '../../../../../../../../services/payments/payments.service'
import { IFinancePaymentsInsertRequest } from '../../../../../../../../services/payments/IPaymentsRequest'
import { AxiosError } from 'axios'
import createPaymentStore from '../../../../../../../../store/createPayment.store'
import { IFinancesPaymentItem } from '../../IFinancesPaymentItem'
import { NotificationAlerts } from '../../../../../../../../components/NotificationAlerts/NotificationAlerts'
import { IAddOplataButton } from './IAddOplataButton'
import paymentsTableStore from '../../../../../../../../store/paymentsTable.store'
import { CustomButton } from '../../../../../../../../components/UI/Buttons/CustomButton/CustomButton'

export const AddOplataButton:FC<IAddOplataButton> = observer((props) => {
  const {onClose, disabled} = props

  const [isLoading, setLoading] = useState(false)
  const [isError, setError] = useState<string[] | null>(null)

  const createOplata = () => {
    if(createPaymentStore.releaseUserId && createPaymentStore.plannedPaidAt && createPaymentStore.releaseUserId){
      setLoading(true)
      setError(null)

      const option: IFinancePaymentsInsertRequest = {
        integrations:createPaymentStore.integrationIds,
        pays:createPaymentStore.pays?.map(item=>{

          const newItem:IFinancesPaymentItem = {
            direction:item.direction,
            paymentType:item.paymentType,
            plannedPaidAt:item.plannedPaidAt,
            source:item.source,
            totalSumm:Number(item.totalSumm),
            cardOrPhone:item.cardOrPhone,
            fio:item.fio,
            paymentLink:item.paymentLink,
            selfEmployedCheck:item.selfEmployedCheck
          } as any

          return newItem
        }),
        releaseUserId:+createPaymentStore.releaseUserId,
        plannedPaidAt:createPaymentStore.plannedPaidAt,
        comment:createPaymentStore.comment || undefined,
        counteragent:createPaymentStore.counteragent || undefined,
      }

      PaymentsService.financePaymentsInsert(option)
        .then(res=>{
          // console.log(res, 'res oplata')
          paymentsTableStore.addTableItem(res.data)
          onClose(res.data?.id)
          setLoading(false)
        })
        .catch(err=>{
          if(err instanceof AxiosError){

            setError(err?.response?.data?.message)
            setLoading(false)
          }
        })

    }
  }

  return (
    <>
      <Box sx={{float:'right', mt:2}}>
        <CustomButton 
          variant='contained' 
          onClick={createOplata}
          disabled={disabled || isLoading}
          endIcon={isLoading ? <CircularProgress sx={{maxHeight:20, maxWidth:20}}/> : null}
        >
          создать оплату
        </CustomButton>
      </Box>
      <NotificationAlerts
        error={{text:Array.isArray(isError) ? isError?.join(', ') : isError || '', open:Boolean(isError), onClose:()=>setError(null)}}
      />
    </>
  )
})