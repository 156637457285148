import { useEffect, useState, FC } from "react"
import { Box, Skeleton } from "@mui/material"
import { observer } from "mobx-react-lite"
import { AxiosError } from "axios"
import userStore from "../../../../store/user.store"
import { IReferencesPresetPropsRequest } from "../../../../services/references/IReferencesRequest"
import ReferencesService from "../../../../services/references/references.service"
import presetFiltersStore from "../../../../store/presetFilters.store"
import { FilterPresetItem } from "../../../Filters/UI/FilterPresetItem/FilterPresetItem"
import channelTableStore from "../../../../store/channelTable.store"
import { getFilterChannelQuery } from "../ChannelFiter/shared/dataList"
import { LS_TAKE_REQUEST_CHANNEL } from "../../../../shared/constants/localstorage"
import { IChannelRequest, allChannelTableCol } from "../../../../services/channel/IChannelRequest"
import ChannelService from "../../../../services/channel/channel.service"
import { checkRemovePresetRole } from "../ChannelFiter/shared/functions"
import { IPresetList } from "../../../IntegrationTable/models/IPresetList"

export const ChannelPresetList:FC<IPresetList> = observer((props) => {
  const {onFilterClose} = props

  const [isLoading, setLoading] = useState(false)

  const getFiltersTableItems = (urlQuery:URLSearchParams) => {
    channelTableStore.setLoading(true)

    const take = localStorage.getItem(LS_TAKE_REQUEST_CHANNEL) ? localStorage.getItem(LS_TAKE_REQUEST_CHANNEL) : 100

    const option:IChannelRequest = {
      company: userStore.currentCompany,
      filterQuery: getFilterChannelQuery(urlQuery),
      includeInSelect: channelTableStore.allFilter as allChannelTableCol[],
      page: 1,
      take: +`${take}`,
    }

    ChannelService.getChannelItems(option)
      .then((res) => {
        // console.log(res, "res confirm filters")
        channelTableStore.setPage(1)
        channelTableStore.setTableItems(res.data.items)
        channelTableStore.setTotalCount(res.data.totalCount)
        channelTableStore.setLoading(false)
        onFilterClose()
      })
      .catch((err) => {
        if (err instanceof AxiosError) {
          // console.log(err, "err filter confirm")
          channelTableStore.setLoading(false)
        }
      })
  }

  const getIntegrationsPreset = () => {
    if (userStore.user) {
      setLoading(true)

      const option: IReferencesPresetPropsRequest = {
        entity: "channel",
        userId: userStore.user?.id,
      }

      ReferencesService.getPresetWithProps(option)
        .then((res) => {
          // console.log(res, "res channel preset")
          presetFiltersStore.setAllPresetChannels(res.data)
          setLoading(false)
        })
        .catch((err) => {
          if (err instanceof AxiosError) {
            // console.log(err, "err channel preset")
            setLoading(false)
          }
        })
    }
  }

  useEffect(() => {
    if(Array.isArray(presetFiltersStore.presetChannels) === false){
      getIntegrationsPreset()
    }
  }, [])

  return (
    <Box>
      {!isLoading && (
        <Box
          sx={{
            display:'grid', 
            columnGap:1, 
            rowGap:1, 
            gridTemplateColumns:'repeat(auto-fill, minmax(125px, max-content))',ml:'30px'
          }}
        >
          {presetFiltersStore.presetChannels &&
            presetFiltersStore.presetChannels.map((item) => (
              <FilterPresetItem
                key={item.id} 
                {...item} 
                onClick={getFiltersTableItems} 
                onDelete={presetFiltersStore.deletePresetChannels}
                readOnly={!checkRemovePresetRole(item.user)}
              />
            ))}
        </Box>
      )}
      {isLoading && presetFiltersStore.presetChannels?.length === 0 &&
       <Skeleton variant="rounded" />
      }
    </Box>
  )
})