import { FC, useState } from 'react'
import { IAddPromokodField } from './IAddPromokodField'
import { Add } from '@mui/icons-material'
import { EditModalField } from '../EditModalField/EditModalField'
import { IInsertPromokodRequest } from '../../../../../services/integration/IntegrationsRequest'
import { AxiosError } from 'axios'
import IntegrationsService from '../../../../../services/integration/integrations.service'
import integrationTableStore from '../../../../../store/integrationTable.store'
import { NotificationAlerts } from '../../../../NotificationAlerts/NotificationAlerts'
import { CustomIconButton } from '../../../../UI/Buttons/CustomIconButton/CustomIconButton'

export const AddPromokodField:FC<IAddPromokodField> = (props) => {
  const {id, readOnly = false} = props

  const [isEdit, setEdit] = useState(false)

  const [isLoading, setLoading] = useState(false)
  const [isError, setError] = useState<string | null>(null)

  const submit = (value:string) => {
    setLoading(true)
    setError(null)

    const option:IInsertPromokodRequest = {
      id,
      promokod:value.replace(/[^a-zA-Z0-9]/g, '').toUpperCase(),
      syncWithSource:true
    }
    IntegrationsService.insertPromokod(option)
      .then(res=>{
        // console.log(res, 'add res from integra card')

        integrationTableStore.insertPromokod({
          id:id,
          promokodCode: res.data.promokod?.code,
          dummyArray: res.data.promokod?.dummies,
          update_id: res.data.update_id,
          newSource: res.data.promokod?.code.toLowerCase(),
          isActivated:res.data.promokod?.isActivated
        })

        setLoading(false)
        setEdit(false)
      })
      .catch(err=>{
        if(err instanceof AxiosError){
          // console.log(err, 'err add prom iuntegr card')
          setLoading(false)
          setError(err.response?.data?.message)
        }
      })
  }


  return (
    <>
      <CustomIconButton disabled={readOnly} onClick={!readOnly ? ()=>setEdit(true) : undefined}>
        <Add/>
      </CustomIconButton>
      {isEdit &&
        <EditModalField
          title='Форма создания промокода'
          open={isEdit}
          onClose={()=>setEdit(false)}
          onSubmit={submit}
          error={isError ? true : false}
          loading={isLoading}
          placeholder='Введите промокод'
          textCancel='отмена'
          textSubmit='создать'
          textCase='uppercase'
          promokodFlag={true}
        />
      }
      <NotificationAlerts
        error={{text:isError ? isError : '', open:isError ? true : false, onClose:()=>setError(null)}}
      />
    </>
  )
}