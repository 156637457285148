import {FC, useState, useEffect} from 'react'
import { IChannelAsideGoods } from './IChannelAsideGoods'
import { AsideCardLayout } from '../../../../components/AsideCardLayout/AsideCardLayout'
import { Stack } from '@mui/material'
import { TableDefault } from '../../../../components/Table/TableDefault'
import { TableItem } from '../../../../components/Table/ui/TableItem'
import { GoodsModalList } from '../../../../components/Table/ui/Fields/GoodsField/ui/GoodsModalList/GoodsModalList'
import { IGoodItem } from '../../../../components/Table/ui/Fields/GoodsField/ui/GoodItem/IGoodItem'
import { IStrategyField } from '../../../../strategy/interface/IStrategyField'
import { observer } from 'mobx-react-lite'
import goodsStore from '../../../../store/goods.store'
import { AddedButton } from '../../../../components/UI/Buttons/AddedButton/AddedButton'

export const ChannelAsideGoods:FC<IChannelAsideGoods> = observer((props) => {
  const {
    goods, 
    setGoods, 
    integrationId,
    forFirstIntegrationWithChannel
  } = props

  const [totalGoods, setTotalGoods] = useState<IGoodItem[]>([])
  const [isShowAdd, setShowAdd] = useState(false)

  const allNameGoods = [...goods].map(item=>item.name)

  const tableRow:IStrategyField[] = [
    {code:'name', title:'Название', immutable:false, isSimple:true},
    {code:'category', title:'Категория', immutable:false, isSimple:false, isGoodCategory:true},
  ]

  const hadelOpen = () => {
    setShowAdd(true)
  }

  const hadelClose = () => {
    if(goodsStore.goods !== null){

      setTotalGoods(prev=>{
        return [...goodsStore.goods!].map(item=>{
  
          if(allNameGoods.find(name=>item.name === name)) {
            return item
          } else return {...item, isActive:false}
      
        })
      })
      
      setShowAdd(false)
    }
  }

  const changeGoods = (article:string, value:boolean) => {
    setTotalGoods(prev=>prev.map(item=>item.article === article ? {...item, isActive:value} : item))
  }

  const submit = () => {
    setGoods(totalGoods.filter(item=>item.isActive))
    setShowAdd(false)
  }

  const deleteItem = (props:any) => {
    setGoods(prev=>prev.filter(item=>item.article !== props.article))
  }
  
  useEffect(()=>{

    if(goodsStore.goods !== null){

      setTotalGoods(prev=>{
        return [...goodsStore?.goods!].map(item=>{
  
          if(allNameGoods.find(name=>item.name === name)) {
            return item
          } else return {...item, isActive:false}
      
        })
      })
      
    }
    
  },[goods])

  return (
    <>
      <AsideCardLayout
        headerTitle='товары'
        requiredTitle
        rightContent={
          <AddedButton variant='outlined' onClick={hadelOpen}>
            Выбрать
          </AddedButton>
        }
      >
        <Stack spacing={1} sx={{mt:1, overflow:'auto', maxHeight:'307px'}}>
          {goods.length > 0 &&
            <TableDefault
              tableRow={tableRow}
              showDeleteIcon
              showBorderRadius={false}
              maxWidth='100%'
            >
              {goods.map(item=>
                <TableItem
                  key={item.article}
                  {...item} 
                  cells={tableRow} 
                  showDeleteIcon
                  onDeleteItem={deleteItem}
                />)
              }
            </TableDefault>
          }
        </Stack>
      </AsideCardLayout>
      {isShowAdd && 
        <GoodsModalList
          open={isShowAdd}
          onClose={hadelClose}
          id={integrationId}
          goods={totalGoods}
          changeGoods={changeGoods}
          onSubmit={submit}
          forFirstIntegrationWithChannel={forFirstIntegrationWithChannel}
        />
      }
    </>
  )
})