import {useMemo} from "react";
import statusesStore from "../../../store/statuses.store";
import {TableItem} from "../../Table/ui/TableItem";
import ChannelService from "../../../services/channel/channel.service";
import {IChannelTransferredList} from "./IChannelTransferredList";
import {useChannelNavigation} from "../../../hooks/useChannelNavigation";

export const ChannelTransferredList = (props:IChannelTransferredList) => {

  const {channels,readOnly,onChange , onChangeManager , readOnlyDateGiven , onChangeStatus, cells } = props

  const navigateChannel = useChannelNavigation();

  const getStatusAllowedMoves = useMemo((): string[] => {
    return Object.keys(statusesStore.channel)
  }, [])

  return (
    <>
      {channels.map((item) => {
        const channelProps = {
          ...item,
          theme: item?.theme?.name,
          readOnly,
          onChange,
          onChangeManager,
          fieldNotCode:'releaseById'
        };
        return <TableItem {...channelProps}
                          readOnlyDateGiven={readOnlyDateGiven}
                          onChangeStatus={onChangeStatus}
                          onUpdateStatus={ChannelService.updateSimpleField}
                          statusStore={statusesStore.channel}
                          cells={cells}
                          key={item.id}
                          isShowNavigateIcon
                          onNavigate={navigateChannel}
                          allAllowedMoves={getStatusAllowedMoves} />;
      })}
    </>
  );
};