import {FC, useState} from 'react'
import { IEditModalField } from './IEditModalField'
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, TextField, Typography } from '@mui/material'
import { Cancel } from '@mui/icons-material'
import { CustomButton } from '../../../../UI/Buttons/CustomButton/CustomButton'
import { CustomIconButton } from '../../../../UI/Buttons/CustomIconButton/CustomIconButton'
import { CancelButton } from '../../../../UI/Buttons/CancelButton/CancelButton'

export const EditModalField:FC<IEditModalField> = (props) => {
  const {
    open,
    onClose,
    onSubmit,
    title,
    error,
    loading,
    textCancel,
    textSubmit,
    placeholder,
    subInputText,
    subContentElemet,
    initValue,
    textCase = 'inherit',
    promokodFlag,
    inputType = 'text',
    required = false
  } = props

  const [value, setValue] = useState(initValue ? initValue : '')
  const textInputHandler = (eventValue: string) =>{
    promokodFlag ?
        setValue(eventValue.replace(/[^a-zA-Z0-9]/g, ''))
        :
        setValue(eventValue)
  }
  const onKeyDownSend = (e:React.KeyboardEvent) => {
    if(e.code === "Enter" || e.code === "NumpadEnter"){
      onSubmit(value)
    }
  }

  return (
    <Dialog
      open={open}
      onClose={loading ? undefined : onClose}
      fullWidth
      maxWidth='sm'
    >
      <DialogTitle sx={{display:'flex', alignItems:'center', justifyContent:'space-between', p:1,pl:{xs:1, sm:2}, fontSize:{xs:'14px', sm:'1.07rem'}}}>
        {title}
        <CustomIconButton disabled={loading} onClick={onClose}>
          <Cancel/>
        </CustomIconButton>
      </DialogTitle>
      <Divider/>
      <DialogContent sx={{p:{xs:1, sm:2}, pt:1, pb:1}}>
        {subInputText &&
          <Typography variant='subtitle1' sx={{mb:0.5}}>
            {subInputText} {required ? <Typography component={'span'} color={'error'}>*</Typography> : null}
          </Typography>
        }
        <TextField
          value={value}
          onChange={e=> textInputHandler(e.target.value)}
          placeholder={placeholder}
          size='small'
          fullWidth
          disabled={loading}
          inputProps={{sx:{textTransform:textCase}}}
          onKeyDown={onKeyDownSend}
          autoFocus
          error={error}
          type={inputType}
          required={required}
        />
        {subContentElemet && subContentElemet}
      </DialogContent>
      <Divider/>
      <DialogActions>
        <CancelButton
          variant='outlined'
          disabled={loading}
          onClick={onClose}
        >
          {textCancel ? textCancel : 'закрыть'}
      </CancelButton>
        <CustomButton            
          variant='contained'
          disabled={loading || value.trim()===''}
          onClick={()=>onSubmit(value)}
          endIcon={loading && <CircularProgress sx={{maxHeight:20, maxWidth:20}} />}
        >
          {textSubmit ? textSubmit : 'Сохранить'}
        </CustomButton>
      </DialogActions>
    </Dialog>
  )
}