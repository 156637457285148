import { FC, useState } from "react"
import { EditModalField } from "../Table/ui/Fields/EditModalField/EditModalField"
import { Box, Checkbox, FormControlLabel } from "@mui/material"
import ReferencesService from "../../services/references/references.service"
import { IReferencesPresetRequest } from "../../services/references/IReferencesRequest"
import { AxiosError } from "axios"
import userStore from "../../store/user.store"
import { useSearchParams } from "react-router-dom"
import { ISaveModalFilter } from "./ISaveModalFilter"
import {purple_color} from "../../app/styles/variables";

export const SaveModalFilter: FC<ISaveModalFilter> = (props) => {
  const { onClose, open, entity, addPresetStore } = props

  const [searchParams] = useSearchParams()

  const [isForAll, setForAll] = useState(false)
  const [isLoading, setLoading] = useState(false)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForAll(event.target.checked)
  }

  const createPreset = (value: string) => {
    if (userStore.user) {
      setLoading(true)

      const option: IReferencesPresetRequest = {
        entity,
        forAll: isForAll,
        name: value,
        preset: searchParams.toString(),
        user: userStore?.user?.id,
      }

      ReferencesService.createPresetFilter(option)
        .then((res) => {
          // console.log(res, "res create preset")
          addPresetStore(res.data)
          setLoading(false)
          onClose()
        })
        .catch((err) => {
          if (err instanceof AxiosError) {
            // console.log(err, "err create preset")
            setLoading(false)
          }
        })
    }
  }

  return (
    <EditModalField
      open={open}
      onClose={onClose}
      title="Сохранить шаблон фильтров"
      onSubmit={createPreset}
      placeholder="Введите название шаблона"
      subContentElemet={
        <Box sx={{ mt: 1 }}>
          <FormControlLabel
            sx={{'&:hover': {'.MuiFormControlLabel-label': {color: purple_color,}}}}
            control={<Checkbox value={isForAll} onChange={handleChange} />}
            label="Сохранить для всех"
          />
        </Box>
      }
      loading={isLoading}
    />
  )
}
