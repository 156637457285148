import {FC, useState} from 'react'
import { ITableBurgerMenu } from '../models/ITableBurgerMenu'
import { Box, Menu, MenuItem } from '@mui/material'
import { Close, Menu as MenuIcon } from '@mui/icons-material'
import { TableBurgerMenuItem } from './TableBurgerMenuItem'
import { CustomIconButton } from '../../UI/Buttons/CustomIconButton/CustomIconButton'

export const TableBurgerMenu:FC<ITableBurgerMenu> = (props) => {
  const {
    lists, 
    restProps, 
    isBanned, 
    update_id,
    enableCodesItem,
    showDelete
  } = props

  const [anchorBurger, setAnchorBurger] = useState<null | HTMLElement>(null)
  const openBurgerMenu = Boolean(anchorBurger)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorBurger(event.currentTarget as any)
  }

  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorBurger(null)
  }

  const openAside = (e?:React.MouseEvent<HTMLElement>, id?:string, onClick?:(id?:string | number, update_id?:number)=>void, onNavigate?:(id:string)=>void, update_id?:number) => {
    e?.stopPropagation()

    if(onNavigate && id){
      onNavigate(id)
    }

    if(onClick && id && update_id){
      onClick(id, update_id)

    } else if (onClick && id) {
      onClick(id)
    }

    if(e) {
      handleClose(e)
    }
  }

  return (
    <>
      <CustomIconButton size='medium' sx={{ml:-1, mr:0}} onClick={(e)=>handleClick(e)}>
        {!openBurgerMenu ? <MenuIcon/> : <Close/>}
      </CustomIconButton>
      <Menu open={openBurgerMenu} anchorEl={anchorBurger} onClose={handleClose}>
        {lists.filter(list=>isBanned ? (list.title !== 'Копировать' && list.title !== 'Создать интеграцию') : list)?.map(item=>{
          return <Box key={item.title}>
              {item.isShow === false || (item?.isShowDeleted && showDelete === false)
                ? null
                : <>
                    {(item.alertProtacted && item.alertProtectedSetting)
                      ? <>
                          {(item.isEnableOnCodes && enableCodesItem && enableCodesItem?.includes(restProps?.status?.code))
                            ? <TableBurgerMenuItem 
                                key={item.title} 
                                title={item.title} 
                                alertProtectedSetting={item.alertProtectedSetting} 
                                onClick={
                                  item?.isUpdateId
                                    ? (e)=>openAside(e, restProps['id'], item.onClick, item.onNavigate, update_id)
                                    : (e)=>openAside(e, restProps['id'], item.onClick, item.onNavigate)
                                }
                              />
                            : null
                          }
                          {(!item.isEnableOnCodes)
                            ? <TableBurgerMenuItem 
                                key={item.title} 
                                title={item.title} 
                                alertProtectedSetting={item.alertProtectedSetting} 
                                onClick={
                                  item?.isUpdateId
                                    ? (e)=>openAside(e, restProps['id'], item.onClick, item.onNavigate, update_id)
                                    : (e)=>openAside(e, restProps['id'], item.onClick, item.onNavigate)
                                }
                              />
                            : null
                          }
                        </>
                      : <MenuItem
                          key={item.title}
                          onClick={
                            item?.isUpdateId
                              ? (e)=>openAside(e, restProps['id'], item.onClick, item.onNavigate, update_id)
                              : (e)=>openAside(e, restProps['id'], item.onClick, item.onNavigate)
                          }
                        >
                          {item.title}
                        </MenuItem>
                    }
                  </>
              }
            </Box>
          })
        }
      </Menu>
    </>
  )
}
