import { FC, memo } from 'react'
import { AsideCardLayout } from "../../../../components/AsideCardLayout/AsideCardLayout"
import { Box, Stack, Typography } from "@mui/material"
import { PaymentCountagentSelect } from "./ui/PaymentCountagentSelect/PaymentCountagentSelect"
import { IFinancesContrAgent } from './IFinancesContrAgent'
import { EditModeButton } from '../../../../components/UI/Buttons/EditModeButton/EditModeButton'
import {EmptyText} from "../../../../components/EmptyText/EmptyText";

export const FinancesContrAgent:FC<IFinancesContrAgent> = memo((props) => {
  const {
    counteragent,
    setCounteragent,
    integrationContacts,
    disabled = false,
    isEditMode,
    isShowEditIcon,
    onCloseEditButton,
    onOpenEditButton,
    onSubmitEditButton,
    loadingEditButton,
    sxContAgentWidth,
    readOnly = false
  } = props

  return (
    <AsideCardLayout 
      headerTitle="контрагент"
      rightContent={
        (isShowEditIcon && onCloseEditButton && onOpenEditButton && onSubmitEditButton) &&
          <EditModeButton
            isEdit={isEditMode}
            onClose={onCloseEditButton}
            onOpenEdit={onOpenEditButton}
            onSubmit={onSubmitEditButton}
            loading={loadingEditButton}
            disabledEdit={readOnly}
          />
      }
    >
      <Box sx={{mt:1.5}}>
        <Box 
          sx={sxContAgentWidth}
        >
          <Stack
            direction={{xs:'column', lg:'row'}} 
            spacing={1} 
            alignItems={{xs:'start', lg:'center'}}
          >
            <Typography>Контрагент:</Typography>
            {isEditMode &&
              <PaymentCountagentSelect
                value={counteragent}
                onChangeValue={setCounteragent}
                placeholder="Введите название или ИНН"
                disabled={disabled}
              />
            }
            {!isEditMode &&
              <Typography noWrap>{counteragent?.shortName || <EmptyText>контрагент не выбран</EmptyText>}</Typography>
            }
          </Stack>
        </Box>
        {counteragent &&
          <Stack spacing={1} sx={{mt:1}}>
            <Typography display={'flex'} gap={1}>ИНН: {counteragent?.inn ||<EmptyText>данные отсутствуют</EmptyText>}</Typography>
            <Typography display={'flex'} gap={1}>Прямой контакт: {integrationContacts?.filter(x=>x!=='').join(', ') ||<EmptyText>данные отсутствуют</EmptyText>}</Typography>
            <Typography display={'flex'} gap={1}>
              ЭДО: {counteragent?.edo === true ? 'есть' : 'нет' || <EmptyText>данные отсутствуют</EmptyText>}
            </Typography>
            <Typography display={'flex'} gap={1}>Оператор: {counteragent?.operator?.name || <EmptyText>данные отсутствуют</EmptyText>}</Typography>
          </Stack>
        }
      </Box>
    </AsideCardLayout>
  )
})