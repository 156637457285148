import { lazy } from 'react'
import { LoginPage } from "../../pages"
import { ChannelAsidePage } from "../../pages/ChannelAsidePage/ChannelAsidePage"
import { HomePage } from "../../pages/HomePage/HomePage"
import { MainPage } from "../../pages/MainPage/MainPage"
import { ACCESS_RULES } from "../rules/rules"
import { CreateChannelPage } from "../../pages/CreateChannelPage/CreateChannelPage"
import { AgreementPage } from "../../pages/DashboardPage/pages/AgreementPage/AgreementPage"
import { MotivationPage } from "../../pages/DashboardPage/pages/MotivationPage/MotivationPage"
import { PlanPage } from "../../pages/DashboardPage/pages/PlanPage/PlanPage"
import { GeneralAnalytics } from '../../pages/AnalyticsPage/pages/GeneralAnalytics/GeneralAnalytics'
import { AllStatisticPage } from '../../pages/AnalyticsPage/pages/AllStatisticPage/AllStatisticPage'
import { RulesPage } from '../../pages/DashboardPage/pages/RulesPage/RulesPage'
import { FinancesCreatePage } from '../../pages/FinancesCreatePage/FinancesCreatePage'
import { GoodsCreatePage } from '../../pages/GoodsCreatePage/GoodsCreatePage'
import {ChannelTable} from "../../components/ChannelTable/ChannelTable";
import {TransferredChannels} from "../../components/TransferredChannels/TransferredChannels";
import {ProcessedChannels} from "../../components/ProcessedChannels/ProcessedChannels";

const ChannelPage = lazy(() => import('../../pages/ChannelPage/ChannelPage'))
const IntegraionsPage = lazy(() => import('../../pages/IntegraionsPage/IntegraionsPage'))
const AnalyticsPage = lazy(() => import('../../pages/AnalyticsPage/AnalyticsPage'))
const ContrActorPage = lazy(() => import('../../pages/ContrActorPage/ContrActorPage'))
const FinancesPage = lazy(() => import('../../pages/FinancesPage/FinancesPage'))
const TzPage = lazy(() => import('../../pages/TzPage/TzPage'))
const DashboardPage = lazy(() => import('../../pages/DashboardPage/DashboardPage'))
const GoodsPage = lazy(() => import('../../pages/GoodsPage/GoodsPage'))

const HOME = '/'

export const ROUTES = {
  HOME,
  COMPANY:':company/',
  LOGIN:'/login',
  INTEGRATIONS:'integrations',
  CHANNELS:'channels',
  FINANCES:'finances',
  ANALYTICS:'analytics',
  DASHBOARD:'dashboard',
  TZ:'tz',
  CONTRACTOR:'contractor',
  GOODS:'goods'
}

export const CHANNELS_ROUTES = {
  INTEGRATION:'integration',
  CREATE:'create',
  MAIN: 'main',
  TRANSFERRED_CHANNELS: 'transferred-channels',
  PROCESSED_CHANNELS: 'processed-channels',
}

export const DASHBOARD_ROUTES = {
  MOTIVATIONS:'motivations',
  AGREEMENTS:'agreements',
  PLAN:'plan',
  RULES:'rules',
}

export const ANALYTICS_ROUTES = {
  MAIN:'main',
  BASIC:'basic',
}

export const CONTRACTOR_ROUTES = {
  CREATE: 'create'
}

export const FINANCES_ROUTES = {
  CREATE: 'create'
}

export const GOODS_ROUTES = {
  CREATE:'create'
}

export const ANOTHER_ROUTES = {
  RETAIL_ORDER:`https://caseguru.retailcrm.ru/orders/`
}

export const analyticsRoutes = [
  {path:ANALYTICS_ROUTES.MAIN, element:GeneralAnalytics},
  {path:ANALYTICS_ROUTES.BASIC, element:AllStatisticPage},
]

export const financesRoutes = [
  {path:FINANCES_ROUTES.CREATE, element:FinancesCreatePage},
]

export const goodsRoutes = [
  {path:GOODS_ROUTES.CREATE, element:GoodsCreatePage},
]

export const channelsRoutes = [

  {
    path: CHANNELS_ROUTES.MAIN + '/*',
    element: ChannelTable,
    isPermission: true,
  },
  {
    path: CHANNELS_ROUTES.TRANSFERRED_CHANNELS,
    element: TransferredChannels,
    isPermission: true,
  },
  {
    path: CHANNELS_ROUTES.PROCESSED_CHANNELS,
    element:ProcessedChannels ,
    isPermission: true,
  },
]

export const channelMainSectionRoutes= [
  {
    path:'/:id/'+CHANNELS_ROUTES.INTEGRATION,
    element:ChannelAsidePage,
    isPermission:true,
    accessRules:ACCESS_RULES.create_integration,
  },
  {
    path:CHANNELS_ROUTES.CREATE,
    element:CreateChannelPage,
    isPermission:true,
    accessRules:ACCESS_RULES.create_channels,
  },
]

export const dashboardRoutes = [
  {
    path:DASHBOARD_ROUTES.AGREEMENTS, 
    element:AgreementPage,
    isPermission:true,
    accessRules:ACCESS_RULES.dashboardAgreements
  },
  {
    path:DASHBOARD_ROUTES.MOTIVATIONS, 
    element:MotivationPage,
    isPermission:true,
    accessRules:ACCESS_RULES.dashboardMotivation
  },
  {
    path:DASHBOARD_ROUTES.PLAN, 
    element:PlanPage,
    isPermission:true,
    accessRules:ACCESS_RULES.dashboardPlan
  },
  {
    path:DASHBOARD_ROUTES.RULES, 
    element:RulesPage,
    isPermission:true,
    accessRules:ACCESS_RULES.dashboardRules
  },
]

export const homePageRoutes = [
  {
    path:ROUTES.COMPANY + ROUTES.INTEGRATIONS,
    element:IntegraionsPage,
    isPermission:true,
    accessRules:ACCESS_RULES.integrations,
  },
  {
    path:ROUTES.COMPANY + ROUTES.FINANCES + '/*',
    element:FinancesPage,
    isPermission:true,
    accessRules:ACCESS_RULES.finances,
  },
  {
    path:ROUTES.COMPANY + ROUTES.DASHBOARD + '/*',
    element:DashboardPage,
    isPermission:true,
    accessRules:ACCESS_RULES.dashboard,
  },
  {
    path:ROUTES.COMPANY + ROUTES.CONTRACTOR + '/*',
    element:ContrActorPage,
    isPermission:true,
    accessRules:ACCESS_RULES.counteragents
  },
  {path:ROUTES.COMPANY + ROUTES.CHANNELS + '/*', element:ChannelPage},
  {
    path:ROUTES.COMPANY + ROUTES.ANALYTICS + '/*', 
    element:AnalyticsPage,
    isPermission:true,
    accessRules:ACCESS_RULES.analytics
  },
  {
    path:ROUTES.COMPANY + ROUTES.TZ, 
    element:TzPage,
    isPermission:true,
    accessRules:ACCESS_RULES.tz
  },
  {path:ROUTES.COMPANY + ROUTES.GOODS + '/*', element:GoodsPage},
  {path:'/', element:MainPage},
]

export const contrAgentRoutes = [
  {
    path:'/:id/'+CHANNELS_ROUTES.INTEGRATION,
    element:ChannelAsidePage,
    isPermission:true,
    accessRules:ACCESS_RULES.create_integration,
  },
]

export const publicRoutesApp = [
  {path:ROUTES.LOGIN, element:LoginPage},
]

export const privateRoutesApp = [
  {path:ROUTES.HOME + '*', element:HomePage},
]