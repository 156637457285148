import { useState, useEffect, FC } from "react"
import { useLocation, useParams} from "react-router-dom"
import { Box, Button, CircularProgress, Drawer, Link, Skeleton, Stack, Typography} from "@mui/material"
import { KeyboardArrowLeft } from "@mui/icons-material"
import { DASHBOARD_ROUTES } from "../../shared/routes/routes"
import userStore from "../../store/user.store"
import { AsideTabs } from "./ui/AsideTabs/AsideTabs"
import { IntegrationMain } from "./pages/Main/IntegrationMain"
import { ITabItem } from "./ui/AsideTabs/IAsideTabs"
import { IntegrationMetric } from "./pages/Metric/IntegrationMetric"
import { IntegrationHistory } from "./pages/History/IntegrationHistory"
import { ICrmOrderByPromoRequest } from "../../services/crm/ICrmRequest"
import CrmService from "../../services/crm/crm.service"
import { AxiosError } from "axios"
import IntegrationsService from "../../services/integration/integrations.service"
import { IIntegrationsFullResponse } from "../../services/integration/IIntegrationsResponse"
import { ICrmOrderByPromoResponse } from "../../services/crm/ICrmRespone"
import { IMetricsOnSourceRequest } from "../../services/metrics/IMetricsRequest"
import MetricsService from "../../services/metrics/metrics.service"
import { IMetricsOnSourceResponse } from "../../services/metrics/IMetricsResponse"
import { getDatePlusCustomTime, getMetricOrderBy } from "../../shared/functions/functions"
import integrationPageStore from "../../store/integrationPage.store"
import { observer } from "mobx-react-lite"
import { RULES } from "../../shared/rules/rules"
import { ALL_RULES } from "../../shared/rules/interface/EnumRights"
import dayjs from "dayjs"
import { IReferencesTzResponse } from "../../services/references/IReferencesResponse"
import { AgreementsPage } from "./pages/AgreementsPage/AgreementsPage"
import { AgreementStatuses } from "../../components/Table/ui/Fields/AgreementStatuses/AgreementStatuses"
import dashboardStore from "../../store/dashboard.store"
import { IAgreementsResponseUpdateStatus, IUserApprovments } from "../../services/agreements/IAgreementsResponse"
import statusesStore from "../../store/statuses.store"
import { ITableAsidePage } from "./ITableAsidePage"
import { IGoodItem } from "../../components/Table/ui/Fields/GoodsField/ui/GoodItem/IGoodItem"
import { CHANNEL_CARD_OPEN } from "../../shared/constants/searchParams"
import { IntegrationOplataPage } from "./pages/IntegrationOplataPage/IntegrationOplataPage"
import { transformPaysData } from "../FinanceCardPage/shared/dataList"
import {editPaymentsPermission} from "../../shared/rules/permission";
import { ResizeButton } from "../../components/ResizeButton/ResizeButton"
import { useResizeHook } from "../../hooks/useResizeHook"
import { LS_SAVE_WIDTH_INTEGRATION } from "../../shared/constants/localstorage"
import { CustomIconButton } from "../../components/UI/Buttons/CustomIconButton/CustomIconButton"

export const TableAsidePage:FC<ITableAsidePage> = observer((props) => {
  const {
    integrationId, 
    onClose, 
    channelSearchParamsKey = CHANNEL_CARD_OPEN
  } = props

  const {id} = useParams()

  const urlId = integrationId ? integrationId : id

  const location = useLocation()

  const [isOpen, setOpen] = useState(false)
  const [subPage, setSubPage] = useState<number>(0)

  const [data, setData] = useState<IIntegrationsFullResponse | null>(null)
  const [cpvValue, setCpvValue] = useState<number | null>(null)
  const [priceValue, setPriceValue] = useState<number | null>(null)
  const [sourceValue, setSourceValue] = useState<string | null>(null)
  const [statusValue, setStatusValue] = useState<string | null>(null)
  const [link, setLink] = useState<string | null>(null)
  const [qrCodeLink, setQrCodeLink] = useState<string | null>(null)
  const [promokodList, setPromokodList] = useState<IIntegrationsFullResponse['promokod'] | null>(null)
  const [integrationDate, setIntegrationDate] = useState<string | null>(null)
  const [update_id, setUpdateId] = useState<number | null>(null)
  const [userApprovments, setUserApprovments] = useState<IUserApprovments[] | null>(null)
  // const [accounts, setAccounts] = useState<IChannelInsertAccountResponse[] | null>(null)
  const [goods, setGoods] = useState<IGoodItem[] | null>(null)
  const [tzValue, setTzValue] = useState<IReferencesTzResponse | null>(null)
  const [goodsCategoryCode, setGoodsCategoryCode] = useState<string | null>(null)

  const [isLoading, setLoading] = useState(false)
  const [isError, setError] = useState<string | null>(null)

  const [dataOrder, setOrderData] = useState<ICrmOrderByPromoResponse | null>(null)
  const [isOrdersLoading, setOrdersLoading] = useState(false)

  const [dataOnSource, setDataOnSource] = useState<IMetricsOnSourceResponse | null>(null)
  const [isMetricLoading, setMetricLoading] = useState(false)
  const [isMetricError, setMetricError] = useState<string | null>(null)

  const [integratinCardTabs] = useState<ITabItem[]>([
    {name:'Общее', value:0},
    {name:'Метрика', value:1},
    // {name:'История', value:2},
    {name:'Оплата', value:3},
    {name:'Согласование', value:4},
  ])

  const checkUserOtherEdit = ():boolean => {
    if(userStore.user?.rights.find(el=>el.code === RULES.integrations.edit_others)) {
      return true
    } else {
      return false
    }
  }

  const checkUserEdit = ():boolean => {
    if(userStore.user?.rights.find(x=>x.code === ALL_RULES.ADMIN)){
      return true
    } else if((checkUserOtherEdit() || userStore?.user?.id === data?.releaseUser?.id) === true) {
      return true
    } else return false
  }

  const getOrdersChart = (code: string, dummies: string[], source: string) => {
    setOrdersLoading(true)

    const option: ICrmOrderByPromoRequest = {
      promos: [code, ...dummies],
      source,
    }

    CrmService.getOrders(option)
      .then((res) => {
        // console.log(res, "res chart")
        setOrderData(res.data)
        setOrdersLoading(false)
      })
      .catch((err) => {
        if (err instanceof AxiosError) {
          // console.log(err, "err chart")
          setOrdersLoading(false)
        }
      })
  }

  const getFullData = (id: number) => {
    setLoading(true)
    setError(null)

    IntegrationsService.getIntegrationFull(id)
      .then(res => {
        //console.log(res.data, "res full intergra")
        setGoods(res.data?.goods)
        // setAccounts(res.data?.channel?.accounts)
        setData(res.data)
        setGoodsCategoryCode(res.data.goodsCategoryCode)
        setIntegrationDate(res.data.integration_date)
        setPromokodList(res.data.promokod)
        setSourceValue(res.data.source)
        setPriceValue(res.data.price)
        setCpvValue(res.data.cpv)
        setTzValue(res.data.tz)
        setUpdateId(res.data.update_id)
        setLink(res.data.link)
        setQrCodeLink(res.data.qrCodeLink)
        setStatusValue(res.data.status?.code)

        integrationPageStore.setVideoReview(res.data.isVideoReview)
        integrationPageStore.setViews(res.data?.views)
        integrationPageStore.setPaidSumm(res.data?.paidSumm)
        // integrationPageStore.setPayments(res.data.payments)
        integrationPageStore.setAverageOrderSumm(res.data.averageCheck)
        integrationPageStore.setPredictionViews(res.data.prediction_views)
        integrationPageStore.setPredictionTraffic(res.data.prediction_traffic)
        integrationPageStore.setPredictionCpv(res.data.prediction_cpv)
        integrationPageStore.setPredictionCtr(res.data.prediction_ctr)
        integrationPageStore.setPredictionCr(res.data.prediction_cr)
        integrationPageStore.setPredictionOrders(res.data.prediction_orders)
        integrationPageStore.setPredictionRoi(res.data.prediction_roi)

        integrationPageStore.setEmptyPayment(res.data?.payment !== null ? false : true)
        integrationPageStore.setAsiedeReleaseUserId(res.data?.releaseUser?.id?.toString() || null)
        integrationPageStore.setReleaseUserId(
          res.data?.payment?.releaseUserId
            ? res.data?.payment?.releaseUserId?.toString()
            : res.data?.releaseUser?.id?.toString()
        )
        integrationPageStore.setPlannedPaidAt(res.data?.payment?.plannedPaidAt || null)
        integrationPageStore.setCounteragent(res.data?.payment?.counteragent || null)
        integrationPageStore.setIntegrationContacts(res.data?.channel?.contact, false)
        integrationPageStore.setIntegrationsIds(res.data?.payment?.integrations || null, res.data.id)
        integrationPageStore.setContractBlock(res.data?.payment?.contractBlock || null)
        integrationPageStore.setOplataId(res.data?.payment?.id || null)
        integrationPageStore.setCeatedAt(res.data?.payment?.createdAt || null)
        integrationPageStore.setPaymentType(res.data?.payment?.paymentType || null)
        integrationPageStore.setStatus(res.data?.payment?.status || null)
        integrationPageStore.setPays(transformPaysData(res.data?.payment?.pays || []))
        integrationPageStore.setPaidAt(res.data?.payment?.paidAt || null)
        integrationPageStore.setOplataBlocked(res.data?.payment?.status?.code === 'cancel' ? true : false)
        integrationPageStore.setPostedComments(res.data?.payment?.posted_comments || null)
        integrationPageStore.setHasSelfEmployedCheck(res.data?.payment?.hasSelfEmployedCheck || null)
        integrationPageStore.setSelfEmployedCheckLink(res.data?.payment?.selfEmployedCheckLink || null)
        integrationPageStore.setActLink(res.data?.payment?.actLink || null)          
        integrationPageStore.setHasAct(res.data?.payment?.hasAct || null)
        integrationPageStore.setServiceProvided(res.data?.payment?.isServiceProvided || null)
        integrationPageStore.setIntegrationId(res.data?.id)
        integrationPageStore.setOrdInfoBlock(res.data?.payment?.ordInfo || null)
        integrationPageStore.setIntegrations(res.data?.payment?.integrations || null)
        integrationPageStore.setGuarantee(res.data?.guarantee || null)
        integrationPageStore.setType(res.data?.channel?.type?.code)

        integrationPageStore.setIntegrationTargetAudience(res.data?.targetAudience || null)

        if(res.data.payment?.totalSumm !== res.data.payment?.paysAmount && res.data.payment?.pays && res.data.payment?.pays?.length>0){
          integrationPageStore.setErrorPayments(`общая сумма платежей не соответствует общей сумме оплаты`)
        } else integrationPageStore.setErrorPayments(null)

        setLoading(false)
      })
      .catch((err) => {
        if (err instanceof AxiosError) {
          // console.log(err, "err")
          setError(`Ошибка ${err.response?.data.statusCode}. ${err.response?.data.message}`)
          setLoading(false)
        }
      })
  }

  const getMetricOnSorce = (source:string, initDate:string) => {
    setMetricLoading(true)
    setDataOnSource(null)
    setMetricError(null)
  
    const option:IMetricsOnSourceRequest = {
      containRobots:false,
      endDate:getDatePlusCustomTime({value:new Date().toISOString(), format:'YYYY-MM-DD'}),
      startDate:getDatePlusCustomTime({value:initDate, format:'YYYY-MM-DD'}),
      groupBy:getMetricOrderBy(initDate, new Date().toISOString()),
      source:source.toLowerCase()
    }
    MetricsService.getMetricsOnSource(option)
      .then(res=>{
        // console.log(res, 'metrics on source')
        setDataOnSource(res.data)
        setMetricLoading(false)
      })
      .catch(err=>{
        if(err instanceof AxiosError){
          // console.log(err, 'err metrick on source')
          setMetricError(err.response?.data.message)
          setMetricLoading(false)
        }
      })
      
    }

  const closeModal = () => {
    setOpen(false)
    integrationPageStore.clearCreateStore(null)
    integrationPageStore.closePaymentStore()
    integrationPageStore.setChatOpen(true)

    setTimeout(() => {
      onClose()
    }, 200)
  }

  const changeStatus = (userAprov: IAgreementsResponseUpdateStatus) => {
    if(userApprovments){

      const newApprovments:IUserApprovments[] = [...userApprovments.map(item=>{

        if(item.user.id === userAprov.approvments[0].user.id){

          return {...item, decisionDate:userAprov.approvments[0].decisionDate, approvedStatus: {...item.approvedStatus, code: userAprov.approvments[0].approvedStatus.code}}
        } else return item
  
      })]

      setUserApprovments(newApprovments)

      setStatusValue(userAprov.integration?.status?.code)
      setUpdateId(userAprov.integration?.update_id)

      if(statusesStore.agreement){

        const currentStatus = statusesStore?.agreement[userAprov.approvments[0].approvedStatus.code]

        dashboardStore.changeAgreementStatus(
          userAprov.id, userAprov.approvments[0].user.id, currentStatus, userAprov.approvments[0].approvedStatus.code, userAprov.agreementStatus.code, userAprov.approvments[0].decisionDate, userAprov.allDecisionDate
        )
      }
    }
  }

  useEffect(()=>{
    if(dashboardStore.agreementsAll && urlId && userApprovments === null){
      setUserApprovments(dashboardStore.agreementsAll?.find(x=>x?.integration?.id === +urlId)?.approvments || null)
    }
  },[dashboardStore.agreementsAll])

  useEffect(() => {
    if (promokodList && sourceValue) {
      getOrdersChart(promokodList.code, promokodList.dummies, sourceValue)
    }
    if(sourceValue && data && data?.integration_date){
      getMetricOnSorce(sourceValue, data?.integration_date)
    }
  }, [sourceValue, promokodList, data])

  useEffect(() => {
    if (urlId) {
      setOpen(true)
      getFullData(+urlId)
      integrationPageStore.setChatOpen(true)
    }
  }, [urlId])

  const [onMouseDown, onMouseMove, onMouseUp, startResize] = useResizeHook('integration-conent', LS_SAVE_WIDTH_INTEGRATION)

  return (
    <Drawer
      anchor={"right"} 
      open={isOpen} 
      onClose={closeModal} 
      sx={{position: "relative"}}
      PaperProps={{sx:{backgroundColor: "#F2F3F4",}}}
      slotProps={{backdrop:{sx:{left:70}}}}
      onMouseMove={startResize ? onMouseMove : undefined}
    >
      <Box
        id={'integration-conent'}
        sx={{
          p:2,
          pt:1,
          width:{
            xl:localStorage.getItem(LS_SAVE_WIDTH_INTEGRATION) ? `calc(${localStorage.getItem(LS_SAVE_WIDTH_INTEGRATION)+'vw'} - 70px)` : "85vw", 
            lg:localStorage.getItem(LS_SAVE_WIDTH_INTEGRATION) ? `calc(${localStorage.getItem(LS_SAVE_WIDTH_INTEGRATION)+'vw'} - 70px)` : 'calc(100vw - 70px - 100px)', 
            md:localStorage.getItem(LS_SAVE_WIDTH_INTEGRATION) ? `calc(${localStorage.getItem(LS_SAVE_WIDTH_INTEGRATION)+'vw'} - 70px)` : 'calc(100vw - 70px - 50px)', 
            sm:'calc(100vw - 70px)', 
            xs:'100vw'
          },
          minWidth:{
            xl:"85vw", 
            lg:'calc(100vw - 70px - 100px)', 
            md:'calc(100vw - 70px - 50px)',
            sm:'calc(100vw - 70px)', 
            xs:'100vw'
          },
          position:'relative'
        }}
      >
        <ResizeButton onMouseDown={onMouseDown} onMouseUp={onMouseUp} sx={{display:{xs:'none', md:'block'}}}/>
        <Stack direction={"row"} alignItems={"center"} spacing={1}>
          <CustomIconButton onClick={closeModal} sx={{ml:-1}}>
            <KeyboardArrowLeft fontSize="large" />
          </CustomIconButton>
          <Typography
            variant="h5"
            noWrap
            sx={{maxWidth:'50%'}}
          >
            Интеграция №{urlId}, {data?.channel?.name}. {data?.integration_date ? dayjs(data?.integration_date).format('DD.MM.YYYY') : null}
          </Typography>
          {tzValue && 
            <Typography variant="h5" noWrap>ТЗ: <Link href={tzValue?.link} target="_blank" color={'secondary'}>{tzValue?.id} {tzValue?.name}</Link></Typography>
          }
          {dashboardStore?.agreementsAll && userStore?.user && urlId && location?.pathname?.includes(DASHBOARD_ROUTES.AGREEMENTS) && userApprovments &&
            dashboardStore?.agreementsAll?.find(x=>x?.integration?.id === +urlId)?.approvments?.find(us=>us?.user?.id === userStore?.user?.id) &&
            <Box sx={{ml:'auto'}}>
              <AgreementStatuses
                agreementId={dashboardStore?.agreementsAll?.find(item=>item?.integration?.id === +urlId)?.id!}
                statusCode={
                  userApprovments?.find(user=>user.user.id === userStore?.user?.id)?.approvedStatus.code!
                }
                userId={userStore?.user?.id}
                agreementStatusCallback={changeStatus}
              />
            </Box>
          }
        </Stack>
        {!isError &&
          <Box sx={{mb:2}}>
            {!isLoading
              ? <AsideTabs initValue={subPage} setValue={setSubPage as any} tabs={integratinCardTabs}/>
              : <Stack direction={'row'} alignItems={'center'} spacing={2} sx={{mt:1}}>
                  {integratinCardTabs.map(item=><Skeleton key={item.value} variant="rounded" height={24} width={90}/>)}
                </Stack>
            }
          </Box>
        }
        {isLoading &&
          <Box sx={{p:2, display: "flex", alignItems: "center", justifyContent: "center", height:"70vh"}}>
            <CircularProgress sx={{ minHeight: 200, minWidth: 200 }} />
          </Box>
        }
        {isError &&
          <Box>
            <Typography variant="h4" color={"error"} align="center">{isError}</Typography>
            <Typography variant="h5" color={"action"} align="center" sx={{mt:1}}>Страница может больше не существовать или быть перемещена на другой веб адрес.</Typography>
            <Button fullWidth variant="outlined" onClick={closeModal} sx={{mt:2, background:'#fff'}}>Вернуться назад</Button>
          </Box>
        }
        {!isLoading && urlId &&
          <Box sx={{height:'calc(100vh - 134px)', overflow:'auto'}}>
            {subPage === 0 &&
              <>
                {!isLoading && data && update_id !== null ?
                  <IntegrationMain
                    id={+urlId}
                    closeModal={closeModal}
                    data={data}
                    dataOrder={dataOrder}
                    isOrdersLoading={isOrdersLoading}
                    cpv={cpvValue}
                    price={priceValue}
                    setCpvValue={setCpvValue as any}
                    setPriceValue={setPriceValue as any}
                    promokodList={promokodList}
                    setPromokodList={setPromokodList as any}
                    dataOnSource={dataOnSource}
                    isMetricError={isMetricError}
                    isMetricLoading={isMetricLoading}
                    date={integrationDate}
                    setDate={setIntegrationDate}
                    tzValue={tzValue}
                    setTzValue={setTzValue}
                    update_id={update_id}
                    setUpdateId={setUpdateId}
                    link={link}
                    setLink={setLink}
                    qrCodeLink={qrCodeLink}
                    setQrCodeLink={setQrCodeLink}
                    statusValue={statusValue}
                    setStatusValue={setStatusValue}
                    goods={goods ? goods : []}
                    setGoods={setGoods}
                    goodsCategoryCode={goodsCategoryCode}
                    setGoodsCategoryCode={setGoodsCategoryCode}
                    readOnly={!checkUserEdit()}
                    channelSearchParamsKey={channelSearchParamsKey}
                    changePage={setSubPage}
                  />
                : null}
              </>
            }
            {subPage === 1 && data ?
              <IntegrationMetric
                id={+urlId}
                closeModal={closeModal}
                dataOrder={dataOrder}
                cac={data?.cac}
                isOrdersLoading={isOrdersLoading}
                integrationDate={integrationDate}
                source={sourceValue}
                dataOnSource={dataOnSource}
                isMetricError={isMetricError}
                isMetricLoading={isMetricLoading}
              />
            : null}
            {/* {subPage === 2 && <IntegrationHistory id={+urlId} closeModal={closeModal} />} */}
            {subPage === 3 && integrationPageStore.isEmpyPaymet !== null && update_id !== null ?
              <IntegrationOplataPage
                isReadOnly={!checkUserEdit()||editPaymentsPermission()}
                setPriceValue={setPriceValue}
                update_id={update_id}
                setUpdateId={setUpdateId}
                onChangeCpv={setCpvValue as any}
                id={+urlId}
              />
              : null
            }
            {subPage === 4 && urlId && update_id &&
              <AgreementsPage
                integrationId={+urlId}
                userApprovments={userApprovments} 
                setUserApprovments={setUserApprovments}
                update_id={update_id}
                setUpdateId={setUpdateId}
                statusValue={statusValue}
                setStatusValue={setStatusValue}
              />
            }
          </Box>
        }
      </Box>
    </Drawer>
  )
})