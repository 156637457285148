import {FC} from 'react'
import { IGoodsCategoryTable } from './IGoodsCategoryTable'
import { observer } from 'mobx-react-lite'
import { IStrategyField } from '../../../../../../strategy/interface/IStrategyField'
import { getGoodsCategoryRows } from './shared/dataList'
import { TableDefault } from '../../../../../../components/Table/TableDefault'
import { TableItem } from '../../../../../../components/Table/ui/TableItem'
import { Avatar, Box, Skeleton, Stack, Typography } from '@mui/material'
import categoriesGoodsStore from '../../../../../../store/categoriesGoods.store'
import channelStore from '../../../../../../store/channel.store'
import { priceTransormFormat } from '../../../../../../shared/functions/functions'

export const GoodsCategoryTable:FC<IGoodsCategoryTable> = observer((props) => {
  const {stats} = props

  const currentGood = categoriesGoodsStore?.categories?.find(x=>x.code === stats.goodsCategory)
  
  const talbleCol:IStrategyField[] = [
    {code:'type', title:'Тип', immutable:false, isSimple:false, isChannelType:true,isShowTypeText:true},
    {code:'cnt', title:'Каналов', immutable:false, isSimple:true},
    {code:'totalPrice', title:'Бюджет', immutable:false, isSimple:true, isMoneySimple:true},
    {code:'ordersCnt', title:'Заказы', immutable:false, isSimple:true},
    {code:'averageCheck', title:'Ср. чек в интеграциях', immutable:false, isSimple:true, isMoneySimple:true},
    {code:'ordersSumm', title:'Сумма', immutable:false, isSimple:true, isMoneySimple:true},
    {code:'averageRoi', title:'ROI', immutable:false, isSimple:true, isPercent:true, isShowRemains:false},
    {code:'averageOrderPrice', title:'Стоимость заказа', immutable:false, isSimple:true, isMoneySimple:true},
    {code:'views', title:'Просмотры', immutable:false, isSimple:false, isSimpleView:true},
    {code:'traffic', title:'Трафик', immutable:false, isSimple:false, isSimpleView:true},
    {code:'averageCPV', title:'CPV', immutable:false, isSimple:true,  isShowRemains:true, ctrlsIcon:'₽'},
    {code:'averageCTR', title:'CTR', immutable:false, isSimple:true, isPercent:true},
    {code:'averageCR', title:'CR', immutable:false, isSimple:true, isPercent:true},
    {code:'averageCRThrough', title:'Скв.CR', immutable:false, isSimple:true, isPercent:true},
  ]

  return (
    <Box>
      <Stack direction={{xs:'column', sm:'row'}} alignItems={{xs:'start', sm:'center'}} spacing={0.5} sx={{mb:1}}>
        <Stack direction={'row'} alignItems={'center'} spacing={0.5}>
          <Typography sx={{fontWeight:'bold'}}>{currentGood?.name}</Typography>
          {currentGood?.iconUrl ? <Avatar src={currentGood?.iconUrl}/> : null}
        </Stack>
        {stats.stats.all.averageCheck !== null ?
            <Stack direction="row">
              <Typography sx={{fontWeight:'bold', marginRight:'3px'}}>Средний чек по товарам: </Typography>
              {priceTransormFormat(+stats.stats.all.averageCheck, true)}
            </Stack>:
            <Stack direction="row">
                <Typography sx={{fontWeight:'bold', marginRight:'3px'}}>Средний чек по товарам: </Typography>
                данных не найдено
            </Stack>
        }
      </Stack>
      {channelStore.channels
        ? <TableDefault showBorderRadius={false} tableRow={talbleCol}>
            {getGoodsCategoryRows(stats, channelStore.channels).map((item, ind)=><TableItem key={item.type.name + ind} cells={talbleCol} {...item}/>)}
          </TableDefault>
        : <Skeleton variant='rounded' sx={{height:100}}/>
      }
    </Box>
  )
})
