import { FC } from 'react'
import { IAlertModal } from './models/IAlertModal'
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider } from '@mui/material'
import { Cancel } from '@mui/icons-material'
import { CustomButton } from '../UI/Buttons/CustomButton/CustomButton'
import { CancelButton } from '../UI/Buttons/CancelButton/CancelButton'
import { CustomIconButton } from '../UI/Buttons/CustomIconButton/CustomIconButton'

export const AlertModal:FC<IAlertModal> = (props) => {
  const {
    open,
    onClose,
    title,
    subtext,
    buttonCancelName,
    buttonSubmitName,
    onSubmit,
    loading = false,
    colorSubmit,
    error,
    subContent,
    disabled
  } = props

  return (
    <Dialog
      open={open}
      onClose={!loading ? onClose : undefined}
      fullWidth
      maxWidth='sm'
    >
      <DialogTitle
        sx={{
          display:'flex', 
          alignItems:'center', 
          justifyContent:'space-between', 
          p:{xs:1, sm:2}, 
          pr:{xs:0.5, sm:0.5},
        }}
      >
        {title}
        <CustomIconButton disabled={loading} onClick={onClose}>
          <Cancel/>
        </CustomIconButton>
      </DialogTitle>
      <Divider/>
      {(subtext !== undefined || subContent !== undefined) &&
        <>
          <DialogContent sx={{pl:2}}>
            {subtext}
            {subContent}
          </DialogContent>
          <Divider/>
        </>
      }
      <DialogActions>
        <CancelButton disabled={loading} variant='outlined' onClick={onClose}>{buttonCancelName}</CancelButton>
        <CustomButton
          disabled={loading || disabled}
          variant='contained'
          onClick={onSubmit}
          color={colorSubmit}
          endIcon={loading && <CircularProgress sx={{maxHeight:20, maxWidth:20}} />}
        >
          {buttonSubmitName}
        </CustomButton>
      </DialogActions>
    </Dialog>
  )
}
