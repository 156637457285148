import {FC} from 'react'
import { ISidebarItem } from './ISidebarItem'
import { Link, useLocation } from 'react-router-dom'
import { Box, ListItemButton, Tooltip } from '@mui/material'
import {purple_color, purple_middle_color} from "../../../../app/styles/variables";



const buttonInteractionStyles = {
  '&:hover': {
    background:purple_middle_color,
  },
  '&:active': {
    background: purple_color,
  }
};

export const SidebarItem:FC<ISidebarItem> = (props) => {
  const {
    url,
    tooltipText,
    onClick,
    icon:IconSvg,
    disabled = false,
    isCustomSvg = false,
    isHover
  } = props

  const location = useLocation()

  const currentUrlPathName = location.pathname

  return (
    <Tooltip title={tooltipText} placement='right'>
      <Box
        sx={{
          display:'flex',
          justifyContent:'center',
          cursor:'pointer',
          background:url ? currentUrlPathName.includes(url) ? purple_color : 'inherit' : 'inherit',
          pt:0.5,
          position:'relative',
          ...((url && !currentUrlPathName.includes(url)) || isHover ? buttonInteractionStyles : {}),
          '&:after':{
            content:'""',
            width:'4px',
            height:'100%',
            background:'rgba(66,58,180,0.6)',
            position:'absolute',
            top:0,
            left:0,
            display:url ? currentUrlPathName.includes(url) ? 'block' : 'none' : 'none'
          }
        }}
      >
        {url
          ? <Link to={url}>
              {isCustomSvg
                ? <IconSvg
                    style={{fill:'#fff'}}
                    fontSize='large'
                  />
                : <IconSvg
                    style={{color:'#fff'}}
                    fontSize='large'
                  />
              }
            </Link>
          : <ListItemButton
              sx={{justifyContent:'center'}} 
              disabled={disabled}
              onClick={onClick}
            >
              <IconSvg style={{color:'#fff'}} fontSize='large'/>
            </ListItemButton>
        }
      </Box>
    </Tooltip>
  )
}
