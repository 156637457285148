import { FC } from 'react'
import { AsideCardLayout } from '../../../../components/AsideCardLayout/AsideCardLayout'
import { IFinancesPayments } from './IFinancesPayments'
import { Box, Stack, Typography } from '@mui/material'
import { FinancesPaymentItem } from './ui/FinancesPaymentItem/FinancesPaymentItem'
import { EditModeButton } from '../../../../components/UI/Buttons/EditModeButton/EditModeButton'
import {EmptyText} from "../../../../components/EmptyText/EmptyText";
import { AddedButton } from '../../../../components/UI/Buttons/AddedButton/AddedButton'

export const FinancesPayments:FC<IFinancesPayments> = (props) => {
  const {
    addPayment,
    pays,
    counteragent,
    changeFieldPaysItem,
    changePaymentType,
    onDeletePayment,
    isEditMode,
    isShowEditIcon,
    isShowPaymentStatus,
    onCloseEditButton,
    onOpenEditButton,
    onSubmitEditButton,
    loadingEditButton,
    showDelete = true,
    disabledAddBtn = false,
    onChangeStatus,
    readOnlyStatus,
    disabledEdit = false,
    update_id,
    isErrorTitle = false,
    errorMessages,
    isTotalSummError
  } = props

  return (
    <AsideCardLayout
      headerTitle='платежи'
      isErrorTitle={isErrorTitle}
      headerSx={{flexDirection:{xs:isShowEditIcon ? 'column' : 'row', sm:'row'}, alignItems:{xs:isShowEditIcon ? 'start' : 'center', sm:'center'}}}
      rightContent={
        <Stack
          direction={'row'} 
          alignItems={'center'} 
          spacing={1} 
          sx={{
            mt:{xs:isShowEditIcon ? 1 : 0, sm:0}, 
            width:{xs:isShowEditIcon ? '100%' : 'inherit', sm:'inherit'}, 
            justifyContent:{xs:isShowEditIcon ? 'space-between' : 'inherit', sm:'inherit'},
          }}
        >
          <AddedButton
            size='small'
            variant='outlined'
            onClick={()=>addPayment('bank')}
            disabled={disabledAddBtn}
          >
            Добавить платёж
          </AddedButton>
          {(isShowEditIcon && onOpenEditButton && onCloseEditButton && onSubmitEditButton) &&
            <EditModeButton
              isEdit={isEditMode}
              onOpenEdit={onOpenEditButton}
              onClose={onCloseEditButton}
              onSubmit={onSubmitEditButton}
              loading={loadingEditButton}
              disabledEdit={disabledEdit}
            />
          }
        </Stack>
      }
    >
      <Box>
        {pays?.length === 0 &&
          <Typography sx={{mt:1}}><EmptyText>Платежи отсутствуют, нажмите добавить платеж</EmptyText></Typography>
        }
        {pays?.length > 0 &&
          pays.map((pay, i)=>{
           return <FinancesPaymentItem
              isTotalSummError={isTotalSummError}
              indexPay={i}
              errorMessages={errorMessages?.filter((el)=>el.includes(`pays.${i}`))}
              key={pay.id}
              id={pay.id}
              title={`Платёж №${i+1}`}
              paymentType={pay.paymentType}
              direction={pay.direction}
              plannedPaidAt={pay.plannedPaidAt}
              source={pay.source}
              totalSumm={pay.totalSumm}
              cardOrPhone={pay.cardOrPhone}
              fio={pay.fio}
              paymentLink={pay.paymentLink}
              selfEmployedCheck={pay.selfEmployedCheck}
              showEditPrice={true}
              onChangeField={changeFieldPaysItem}
              counterAgentName={counteragent?.shortName}
              showDivider={pays.length === i+1 ? false : true}
              changePaymentType={changePaymentType}
              onDeletePayment={onDeletePayment}
              paymentStatusCode={pay?.status?.code}
              isShowPaymentStatus={isShowPaymentStatus}
              isEditMode={isEditMode}
              showDelete={showDelete}
              onChangeStatus={onChangeStatus}
              paidAt={pay?.paidAt}
              readOnlyStatus={readOnlyStatus}
              update_id={update_id}
            />
          }
          )
        }
      </Box>
    </AsideCardLayout>
  )
}