import {makeAutoObservable} from "mobx"
import {IChannel, IChannelFullResponse} from "../services/channel/ICnannelResponse";


class ChannelProcessedStore {
  tableItems: IChannel[] = []

  constructor() {
    makeAutoObservable(this)
  }

  setTableItems(items: IChannel[]) {
    this.tableItems = items
  }

  deleteProcessedChannelsItem = (item: IChannelFullResponse) => {
    if (this.tableItems) {
      if (!item.transfer.isProcessed){
        this.tableItems = this.tableItems.filter(el => el.id !== item.id)
      }
    }
  }
  deleteProcessedItem = (item: IChannelFullResponse) => {
    if (this.tableItems) {
      this.tableItems = this.tableItems.filter(el => el.id !== item.id)
    }
  }
  changeUpdateIdItem = (id:number, update_id:number, type:string) =>{
    this.tableItems = this.tableItems.map(item=>{
      if(item.id === id){
        const newItem = {
          ...item,
          update_id,
          status:{code:type}
        }
        return newItem
      } return item
    })
  }

  addReleaseByManager = (id:number, field:string, update_id:number, value:number) =>{
    this.tableItems = this.tableItems.map(item=>{
      if(item.id === id){
        const newItem = {
          ...item,
          update_id,
          [field]:{id:value}
        }
        return newItem
      } return item
    })
  }

  sortTableItem(type: string, sortType: 'asc' | 'desc') {

    this.tableItems.sort((a: any, b: any) => {
      const aValue = a[type];
      const bValue = b[type];

      if (aValue === null || aValue === undefined) return 1;
      if (bValue === null || bValue === undefined) return -1;

      const dateA = new Date(aValue);
      const dateB = new Date(bValue);

      return sortType === 'desc' ? dateA.getTime() - dateB.getTime() : dateB.getTime() - dateA.getTime();
    });
  }


}

export default new ChannelProcessedStore()