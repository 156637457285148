import {FC} from 'react'
import { IPrevMonthMotivation } from './IPrevMonthMotivation'
import { observer } from 'mobx-react-lite'
import { IUser } from '../../../../../../entities/user/models/IUser'
import AllUsersStore from '../../../../../../store/AllUsers.store'
import { IStrategyField } from '../../../../../../strategy/interface/IStrategyField'
import { Box, Typography } from '@mui/material'
import { TableDefault } from '../../../../../Table/TableDefault'
import { ALL_ROLES } from '../../../../../../shared/rules/interface/EnumRights'
import { TableItem } from '../../../../../Table/ui/TableItem'

export const PrevMonthMotivation:FC<IPrevMonthMotivation> = observer((props) => {
  const {
    managerId,
    prevMonthMotivationReleases,
    sxProps,
    talbeHeaderColor
  } = props

  const currentUser:undefined | IUser = AllUsersStore?.users?.find(x=>x?.id === managerId)

  const talbeRow:IStrategyField[] = [
    {title:'ID', code:'id', immutable:false, isSimple:true},
    {title:'Дата выпуска', code:'integration_date', immutable:false, isSimple:false, isDate:true},
    {title:'Имя канала', code:'channel', immutable:false, isSimple:false, isChannelType:true, maxWidth:'200px', isShowTypeText:true},
    {title:'Промокод', code:'promokod', immutable:false, isSimple:true},
    {title:'Заказы', code:'validOrders', immutable:false, isSimple:false, isSimpleView:true},
    {title:'Стоимость', code:'price', immutable:false, isSimple:false, isIntegrationPaidSumm:true},
    {title:'Выручка', code:'ordersSumm', immutable:false, isSimple:false, isIntegrationPaidSumm:true},
    {title:'ROI', code:'roi', immutable:false, isSimple:true, isPercent:true},
    {title:'Площадка', code:'channelType', immutable:false, isSimple:true},
    {title:'Старый / Новый', code:'firstIntegrationWithChannel', immutable:false, isSimple:false, isFirstIntegrationWithChannel:true},
    {title:'Кем найден', code:'userId', immutable:false, isSimple:false, isManagerUser:true},
    {title:'Коэффициент', code:'coef', immutable:false, isSimple:true},
    {title:'Итого', code:'totalPrice', immutable:false, isSimple:false, isIntegrationPaidSumm:true},
  ]

  return (
    <Box sx={{mt:2, pl:2, pr:2, mb:2}}>
      <Typography fontWeight={'bold'} sx={{mb:2}}>Детализация KPI за выпущенные интеграции предыдущего месяца</Typography>
      <TableDefault
        tableRow={
          currentUser?.role === ALL_ROLES.PRE_HEAD_MANAGER || currentUser?.role === ALL_ROLES.RELEASE_MANAGER || currentUser?.role === ALL_ROLES.SEARCH_HEAD_MANAGER
            ? talbeRow : [...talbeRow].filter(x=>x.code !== 'coef')
        }
        showBorderRadius={false}
        maxWidth='100%'
        sx={{
          ...sxProps,
          "& .MuiTableCell-root": { borderLeft: "none" },
          borderLeft:'1px solid #e0e0e0'
        }}
        talbeHeaderColor={talbeHeaderColor}
      >
        {prevMonthMotivationReleases.map(item=>{
          const props = {
            ...item.integration,
            coef:item.coef,
            totalPrice:
            currentUser?.role === ALL_ROLES.PRE_HEAD_MANAGER || currentUser?.role === ALL_ROLES.RELEASE_MANAGER || currentUser?.role === ALL_ROLES.SEARCH_HEAD_MANAGER
              ? item.releaseMotivationPrice
              : item.searchMotivationPrice,
            channel:item.integration?.channel,
            channelType:item.integration?.channel?.type?.name!,
            promokod:item.integration?.promokod?.code,
            userId:item.integration?.channel?.foundById,
          }
          return <TableItem
              key={item.integration.id} 
              cells={
                currentUser?.role === ALL_ROLES.PRE_HEAD_MANAGER || currentUser?.role === ALL_ROLES.RELEASE_MANAGER || currentUser?.role === ALL_ROLES.SEARCH_HEAD_MANAGER
                ? talbeRow : [...talbeRow].filter(x=>x.code !== 'coef')
              }
              hover
              {...props} 
              sx={{p:1}}
              backgroundColor={item.isROIUpdated ? '#FFE14226' : undefined}
            />
        })}
      </TableDefault>
    </Box>
  )
})