import {FC, memo} from 'react'
import { Box, Divider, Stack, Tooltip, Typography } from '@mui/material'
import { ChannelAudienceItem } from '../ChannelAudienceItem/ChannelAudienceItem'
import { IChannelAudienceList } from './IChannelAudienceList'
import { ErrorOutline } from '@mui/icons-material'
import dayjs from 'dayjs'
import { CustomIconButton } from '../../../../../../components/UI/Buttons/CustomIconButton/CustomIconButton'

export const ChannelAudienceList:FC<IChannelAudienceList> = memo((props) => {
  const {
    list,
    type,
    allAge,
    statisticDate,
    isEdit,
    disabledInputs
  } = props

  return (
    <Box>
      {list?.headerStats?.filter(x=>x?.code === 'audience')?.map((item, i)=>
        <ChannelAudienceItem key={item.code + item?.text + i} {...item} isEdit={isEdit} disabled={disabledInputs}/>)
      }
      <Box sx={{display:'flex', justifyContent:'space-between', flexWrap:'wrap', mt:1}}>
        {list?.headerStats?.filter(x=>x?.code !== 'audience')?.map((item, i)=>
          <Box key={item.code + item?.text + i} sx={{mb:1}}><ChannelAudienceItem {...item} isEdit={isEdit} disabled={disabledInputs}/></Box>)
        }
      </Box>
      {type !== 'telegram' &&
        <Box 
          sx={{
            display:'flex', 
            justifyContent:'space-between',
            flexDirection:{xs:'column-reverse', sm:'row'}
          }}
        >
          <Stack spacing={1}>
            <Box>
              <Typography variant='h6'>Пол</Typography>
              <Stack spacing={1}>
                {list?.gender?.map((item, i)=>
                  <ChannelAudienceItem shouldClampValue key={item.code + item?.text + i} {...item} isEdit={isEdit} disabled={disabledInputs}/>)
                }
              </Stack>
            </Box>
            <Box>
              <Typography variant='h6'>География</Typography>
              <Stack spacing={1}>
                {list?.countries?.map((item, i)=>
                  <ChannelAudienceItem shouldClampValue key={item.code + item?.text + i} {...item} isEdit={isEdit} disabled={disabledInputs}/>)
                }
              </Stack>
            </Box>
          </Stack>
          <Box>
            <Typography variant='h6' whiteSpace={'nowrap'}>Возраст аудитории</Typography>
            <Stack spacing={isEdit ? 1 : 1.5} sx={{mt:isEdit ? 0 : 1}}>
              {list?.ages?.map((item, i)=>
                <ChannelAudienceItem shouldClampValue key={item.code + item?.text + i} {...item} isEdit={isEdit} disabled={disabledInputs}/>)
              }
              <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                <Typography>Всего:</Typography>
                <Box sx={{display:{xs:'flex', sm:'none'}, alignItems:'center'}}>
                <Typography>{allAge}</Typography>
                <Typography>%</Typography>
                </Box>
                <Typography sx={{display:{xs:'none', sm:'block'}}}>{allAge}</Typography>
                <Typography sx={{display:{xs:'none', sm:'block'}}}>%</Typography>
              </Stack>
            </Stack>
          </Box>
        </Box>
      }
      {type === 'telegram' &&
        <Stack spacing={1}>
          {list?.telegramStats?.map((item, i)=>
            <ChannelAudienceItem key={item.code + item?.text + i} {...item} isEdit={isEdit} disabled={disabledInputs}/>)
          }
        </Stack>
      }
      {statisticDate !== undefined &&
        <>
          <Divider sx={{borderStyle:'dashed', mt:2, mb:2}}/>
          <Stack direction={'row'} alignItems={'center'} spacing={1}>
            <Stack direction={'row'} alignItems={'center'} spacing={0.5}>
              <Typography>Дата заполнения статистики</Typography>
              {statisticDate === null &&
                <Tooltip title={<Typography>{'Для отображения «Дата заполнения статистики», заполните все обязательные поля в блоке «Aудитория»'}</Typography>}>
                  <Box>
                    <CustomIconButton>
                      <ErrorOutline fontSize='small'/>
                    </CustomIconButton>
                  </Box>
                </Tooltip>
              }
            </Stack>
            {statisticDate && <Typography>{dayjs(statisticDate)?.format('DD.MM.YYYY')}</Typography>}
          </Stack>
        </>
      }
    </Box>
  )
})