import { checkboxClasses, radioClasses } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { black_color, purple_color, purple_light_color, purple_middle_color, red_color, red_light_color } from './styles/variables';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#1e293b',
    },
    secondary: {
      main: '#5F82BA',
    },
  },
  typography: {
    "fontFamily": `"Roboto", "Helvetica", "Arial", sans-serif`,
    "fontSize": 12,
    "fontWeightLight": 300,
    "fontWeightRegular": 400,
    "fontWeightMedium": 500,
  },
  components:{
    MuiLink:{
      styleOverrides:{
        root:{
          color:purple_color,
          textDecorationColor:purple_color,
          '&:hover':{
            color:purple_middle_color
          }
        }
      }
    },
    MuiCheckbox:{
      styleOverrides:{
        root:{
          color: black_color,
          '&:hover':{
            color:purple_middle_color,
            backgroundColor:purple_light_color
          },
          [`&.${checkboxClasses.checked}`]: {
            color: black_color,
          },
        }
      }
    },
    MuiRadio:{
      styleOverrides:{
        root:{
          color: black_color,
          '&:hover':{
            color:purple_middle_color,
            backgroundColor:purple_light_color
          },
          [`&.${radioClasses.checked}`]: {
            color: black_color
          },
        }
      }
    },
    MuiChip:{
      styleOverrides:{
        deleteIcon:{
          color:red_color,
          '&:hover':{
            color:'rgb(255 82 82 / 80%)'
          }
        }
      }
    },
    MuiSelect:{
      styleOverrides:{
        icon:{
          color:purple_middle_color
        }
      }
    },
    MuiAutocomplete:{
      styleOverrides:{
        clearIndicator:{
          color:red_color,

          '&:hover':{
            backgroundColor:red_light_color
          }
        },
        popupIndicator:{
          color:purple_middle_color,
          '&:hover':{
            backgroundColor:purple_light_color
          }
        }
      }
    },
    MuiTableCell:{
      styleOverrides:{
        root:{
          padding:'4px 8px'
        }
      }
    }
  },
});